<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1>White Label</h1>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form :validation-schema="whiteLabelSchema" v-slot="{ errors }" ref="white-label-form">
                    <div class="capsule_elm py-1">
                        <h5>White label</h5>
                        <label for="white_label" class="switch_option capsule_btn" @click="handleMembershipAlert()">
                            <input type="checkbox" id="white_label" :disabled="membership.has_white_label == 0" :true-value="1" :false-value="0" v-model="whiteLabel.has_label" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="setting_wpr" v-show="whiteLabel.has_label && membership.has_white_label">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Powered by</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.powered_by }">
                                    <Field autocomplete="nofill" type="text" name="powered_by" v-model="whiteLabel.powered_by" placeholder="ex: Synergy Fitness" />
                                </div>
                                <ErrorMessage name="powered_by" class="text-danger" />
                            </div>
                        </div>
                    </div>
                    <div class="capsule_elm py-1" v-show="whiteLabel.has_label && membership.has_white_label">
                        <h5>Link out</h5>
                        <label for="link_out" class="switch_option capsule_btn">
                            <input type="checkbox" id="link_out" v-model="whiteLabel.has_link" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="setting_wpr" v-show="whiteLabel.has_label && membership.has_white_label">
                        <div class="form_grp" v-if="whiteLabel.has_link">
                            <div class="group_item">
                                <label class="input_label">Link To</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.link }">
                                    <Field autocomplete="off" type="text" name="link" v-model="whiteLabel.link" placeholder="ex: https://mycompany.com"/>
                                </div>
                                <ErrorMessage name="link" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </Form>
                <div class="capsule_elm py-1">
                    <h5>Use Custom Domain<span>This feature may require a one-time support premium.</span></h5>
                    <label for="cust_domain" class="switch_option capsule_btn" @click="handleMembershipAlert()">
                        <input type="checkbox" id="cust_domain" :disabled="membership.has_white_label == 0" :true-value="1" :false-value="0" v-model="customDomain" @change="handleCustomDomainToggle()" hidden>
                        <div><span></span></div>
                    </label>
                </div>
                <div v-if="customDomain && membership.has_white_label" class="mt-4">
                    <div class="custDomain_info">
                        <p>Setting up your custom domain requires technical knowledge!</p>
                        <h5 class="border-bottom">Checklist</h5>
                        <ul>
                            <li><i class="fas fa-check"></i>Purchased domain name</li>
                            <li><i class="fas fa-check"></i>Domain hosting</li>
                            <li><i class="fas fa-check"></i>Cloudflare account or wildcard SSL certificate</li>
                            <li><i class="fas fa-check"></i>DNS experience</li>
                            <li><i class="fas fa-check"></i>Nameserver experience</li>
                            <li><i class="fas fa-check"></i>Propagation experience</li>
                        </ul>
                        <p>If you lack experience with the above we recommend that you purchase our custom domain setup and testing for $97 below or continue using your Thrive Coach subdomain.</p>
                    </div>
                    <div class="action_wpr mt-4">
                        <a href="https://briandevlin.samcart.com/products/custom-domain-service/" target="_blank" class="btn cancel_btn border-blue">Purchase Domain Setup</a>
                    </div>
                    <div class="section_wpr mt-5">
                        <h3 class="sub_header">All Domains<button type="button" class="add_btn" @click="addDomain = true"><i class="fas fa-plus"></i>Add Domain</button></h3>
                    </div>
                    <div class="result_wpr new small m-0">
                        <div class="actions">
                            <ul>
                                <li class="list_info">
                                    <label for="check" class="checkbox">
                                        <input type="checkbox" id="check" v-model="selectAll" @click="toggleAll" hidden>
                                        <span><i class="fas fa-check"></i></span>
                                    </label>
                                </li>
                                <li class="optionDrops contacts-tabs" @click="bulkAction = !bulkAction">Bulk Action<i class="fas fa-angle-down"></i>
                                    <div class="dropdown_wpr" :class="bulkAction ? 'active' : ''">
                                        <ul>
                                            <li @click="handleVerifyDomain()">Verify Domain</li>
                                            <li @click="handleDeleteDomain()">Delete Domain</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="optionDrops search_area ml-auto active">
                                    <input type="text" placeholder="Search" :disabled="domainLoader" ref="search" @input="isTyping = true" v-model.trim="domainParams.search" />
                                    <button class="search_btn">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div v-if="domainLoader"><line-loader /></div>
                        <div class="scroll_table">
                            <table class="show_header">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-click-outside="closeDomainDropdown">
                                    <tr v-for="(domain, d) of domains.data" :key="d">
                                        <td>
                                            <label :for="`domain-${domain.id}`" class="checkbox">
                                                <input type="checkbox" :id="`domain-${domain.id}`" :value="domain.id" v-model="selectedDomain" hidden>
                                                <span><i class="fas fa-check"></i></span>
                                            </label>
                                        </td>
                                        <td class="break-all">
                                            <h4>{{ domain.name }}</h4>
                                        </td>
                                        <td>{{ domain.verified ? 'Verified' : 'Unverified' }} | {{ domain.activated ? 'Active' : 'Inactive' }}</td>
                                        <td>
                                            <div class="dropdown" :class="{ active: selectedDomainDropdown == domain.id }" @click="toggleDomainDropdown(domain.id)">
                                                <i class="fas fa-ellipsis-v"></i>
                                                <div class="drp_wrapper">
                                                    <ul>
                                                        <li @click="domainForm = JSON.parse(JSON.stringify(domain)); addDomain = true; selectedDomainDropdown = 0;">Edit</li>
                                                        <li v-if="domain.verified == 0" @click="handleVerifyDomain(domain.id)">Verify Domain</li>
                                                        <li @click="domainConfiguration = true; activeDomain = domain;">Domain Configration</li>
                                                        <li @click="handleActivateDomain(domain)" v-if="domain.activated == 0">Activate</li>
                                                        <li @click="handleDeleteDomain(domain.id)">Delete</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="pagination" v-show="domains.total">
                        <pagination v-model="domainParams.page" :pages="domains.last_page" :range-size="0" @update:modelValue="handlePagination" />
                    </div>
                    <div v-if="addDomain" class="details_wpr">
                        <div class="cardItem_details">
                            <Form @keydown.enter="$event.preventDefault()" @submit="handleDomainSave" :validation-schema="schema" v-slot="{ errors }" class="tab_content">
                                <div class="header">
                                    <button class="close_btn" @click="addDomain = false"><i class="fas fa-chevron-down"></i></button>
                                    <h2>{{ domainForm.id ? 'Edit Domain' : 'Add New Domain' }}</h2>
                                </div>
                                <div class="setting_wpr">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Domain Name</label>
                                            <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                                <Field autocomplete="nofill" type="text" name="name" v-model="domainForm.name" placeholder="ex: mycompany.com" />
                                            </div>
                                            <ErrorMessage name="name" class="text-danger" />
                                        </div>
                                    </div>
                                    <label for="default_domain" class="switch_option capsule_btn">
                                        <h5>Use As Default<span>This feature use set the Default domain.</span></h5>
                                        <input type="checkbox" id="default_domain" :true-value="1" :false-value="0" v-model="domainForm.use_default" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <h3>Domain For</h3>
                                <div class="edit_section">
                                    <label for="main_domain" class="switch_option capsule_btn">
                                        <h5>Main Account<span>This feature use set the Default domain.</span></h5>
                                        <input :disabled="domainForm.id" name="domain_for" type="radio" id="main_domain" :value="0" v-model="domainForm.domain_for" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <label for="portal_domain" class="switch_option capsule_btn">
                                        <h5>Client Portal<span>This feature use set the Default domain.</span></h5>
                                        <input :disabled="domainForm.id" name="domain_for" type="radio" id="portal_domain" :value="1" v-model="domainForm.domain_for" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="action_wpr fixed_footer">
                                    <button :disabled="domainSaveLoader" class="btn cancel_btn" type="button" @click="addDomain = false">Cancel</button>
                                    <button :disabled="domainSaveLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="domainSaveLoader"></i> {{ domainSaveLoader ? 'Saving' : 'Save' }}</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div v-if="domainConfiguration" class="details_wpr">
                        <div class="cardItem_details">
                            <div class="header">
                                <button class="close_btn" @click="domainConfiguration = false"><i class="fas fa-chevron-down"></i></button>
                                <h2>Domain Configuration</h2>
                            </div>
                            <div class="tab_content">
                                <div class="edit_section py-4 mt-4">
                                    <h4 class="label">Domain Name:</h4>
                                    <div class="domain_name">{{ activeDomain.name }}</div>
                                    <h4 class="label">Configure your domain</h4>
                                    <p class="domain_info">To get your custom domain working you will need to add the following DNS records via your domain provider and click verify.</p>
                                </div>
                                <div class="result_wpr small new mt-4">
                                    <div class="scroll_table">
                                        <table class="show_header">
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Host</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>CNAME</td>
                                                    <td>{{ activeDomain.host }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="action_wpr fixed_footer">
                                <button class="btn cancel_btn" type="button" @click="domainConfiguration = false">Cancel</button>
                                <button class="btn save_btn" @click="handleVerifyDomain(activeDomain.id)">Verify Domain</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="whiteLabelLoader" class="btn cancel_btn" type="button" @click="closeModal()">Cancel</button>
                <button :disabled="whiteLabelLoader" class="btn save_btn" @click="handleWhiteLabelSave()"><i class="fa fa-spin fa-spinner" v-if="whiteLabelLoader"></i> {{ whiteLabelLoader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'
    import Swal from 'sweetalert2'
    import * as yup from 'yup'

    export default {
        name: 'White label',

        data () {
            const schema = yup.object({
                name: yup.string().required().label('The name'),
            });

            const whiteLabelSchema = yup.object({
                powered_by: yup.string().required().label('The powered by'),
                link: yup.string().url().label('The link'),
            });

            return {
                customDomain: 0,
                addDomain: false,
                bulkAction: false,
                whiteLabel: {},
                domainForm: {
                    domain_for: 0,
                    name: '',
                    use_default: 1,
                },
                selectedDomain: [],
                schema,
                whiteLabelSchema,
                domainParams: {
                    per_page: 5,
                    order_by: 'id',
                    order: 'desc',
                    page: 1,
                    search: '',
                },
                selectedDomainDropdown: 0,
                selectAll: false,
                domainConfiguration: false,
                activeDomain: {},
                isTyping: false,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            // whiteLabel: {
            //     handler () {
            //         const vm = this;
            //         const form  = vm.$refs['white-label-form'];
            //
            //         if (form) {
            //             form.resetForm();
            //         }
            //     },
            //     deep: true,
            // },

            'domainParams.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.domainParams.search != null) {
                        if (vm.domainParams.search.length >= 2) {
                            vm.domainParams.page = 1;
                            vm.getDomains(vm.domainParams);
                        }
                        if (vm.domainParams.search.length === 0) {
                            vm.domainParams.page = 1;
                            vm.getDomains(vm.domainParams);
                        }
                    }
                }
            },

            modelValue (value) {
                if (value) {
                    const vm = this;
                    document.body.classList.add('modal-open');

                    setTimeout(function () {
                        vm.getDomains(vm.domainParams);
                    }, 2000);

                    vm.resetForm();
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            membership: state => state.authModule.membership,
            whiteLabelLoader: state => state.authModule.whiteLabelLoader,
            domains: state => state.domainModule.domains,
            domainLoader: state => state.domainModule.domainLoader,
            domainSaveLoader: state => state.domainModule.domainSaveLoader,
        }),

        mounted () {
            const vm = this;

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
            vm.customDomain = vm.user.use_custom_domain ? vm.user.use_custom_domain : 0;
        },

        methods: {
            ...mapActions({
                updateWhiteLable: 'authModule/updateWhiteLable',
                getDomains: 'domainModule/getDomains',
                verifyDomain: 'domainModule/verifyDomain',
                deleteDomain: 'domainModule/deleteDomain',
                activateDomain: 'domainModule/activateDomain',
                saveDomain: 'domainModule/saveDomain',
                enableDisableDomain: 'domainModule/enableDisableDomain',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleWhiteLabelSave () {
                const vm = this;
                const form = vm.$refs['white-label-form'];

                form.validate().then((result) => {
                    if (result.valid) {
                        const params = vm.whiteLabel;
                        params.setFieldError = form.setFieldError;
                        params.closeModal = vm.closeModal;

                        vm.updateWhiteLable(params);
                    } else if (result.valid == false && !vm.whiteLabel.has_label) {
                        vm.closeModal();
                    }
                });
            },

            toggleDomainDropdown (id) {
                const vm = this;

                vm.selectedDomainDropdown = id;
            },

            closeDomainDropdown () {
                const vm = this;

                vm.selectedDomainDropdown = 0;
            },

            handleDomainSave (params, { setFieldError }) {
                const vm = this;
                const form = vm.domainForm;
                form.setFieldError = setFieldError;

                vm.saveDomain(form).then((result) => {
                    if (result) {
                        vm.addDomain  = false;
                        vm.domainForm = { domain_for: 0, name: '', use_default: 1 };

                        vm.getDomains(vm.domainParams);
                    }
                });
            },

            handleVerifyDomain (id = 0) {
                const vm = this;
                const ids = id ? [id] : vm.selectedDomain;
                const selected = vm.domains.data.filter(({ id }) => ids.includes(id));
                const names = selected.map(({ name }) => name);
                const options = Helper.swalConfirmOptions('Are you sure?', `You want to verify ${ names.join(', ') } ${ names.length > 1 ? 'domains' : 'domain' }.`);
                options.preConfirm = () => {
                                                return vm.verifyDomain(ids).then((result) => {
                                                    if (result) {
                                                        vm.getDomains(vm.domainParams);
                                                        vm.domainConfiguration = false;
                                                    }
                                                });
                                            }

                Swal.fire(options);
            },

            handleDeleteDomain (id = 0) {
                const vm = this;
                const ids = id ? [id] : vm.selectedDomain;
                const selected = vm.domains.data.filter(({ id }) => ids.includes(id));
                const names = selected.map(({ name }) => name);
                const options = Helper.swalConfirmOptions('Are you sure?', `You want to delete ${ names.join(', ') } ${ names.length > 1 ? 'domains' : 'domain' }, you will not be able to recover ${ names.length > 1 ? 'these' : 'this' } ${ names.length > 1 ? 'domains' : 'domain' }.`);
                options.preConfirm = () => {
                                                return vm.deleteDomain(ids).then((result) => {
                                                    if (result) {
                                                        vm.getDomains(vm.domainParams);
                                                    }
                                                });
                                            }

                Swal.fire(options);
            },

            handleActivateDomain (domain) {
                const vm = this;

                if (domain.verified) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to activate ${ domain.name } domain.`);
                    options.preConfirm = () => {
                                                    return vm.activateDomain(domain.id).then((result) => {
                                                        if (result) {
                                                            vm.getDomains(vm.domainParams);
                                                            vm.domainConfiguration = false;
                                                        }
                                                    });
                                                }

                    Swal.fire(options);
                } else {
                    Toastr.error('Please verify domain before activate!');
                }
            },

            toggleAll () {
                const vm = this;

                if (!vm.selectAll) {
                    vm.selectedDomain = vm.filterIndexValue(vm.domains.data);
                } else {
                    vm.selectedDomain = [];
                }
            },

            filterIndexValue (object, index = 'id') {
                const records = [];

                object.forEach((obj) => { records.push(obj[index]) });

                return records;
            },

            handlePagination (page) {
                const vm = this;

                vm.domainParams.page = page;
                vm.getDomains(vm.domainParams);
            },

            resetForm () {
                const vm    = this;

                vm.addDomain    = false;
                vm.bulkAction   = false;
                vm.selectAll    = false;
                vm.isTyping     = false;
                vm.customDomain = vm.user.use_custom_domain ? vm.user.use_custom_domain : 0;
                vm.domainConfiguration  = false;

                const form  = vm.$refs['white-label-form'];

                if (form) {
                    form.resetForm();
                }
            },

            handleCustomDomainToggle () {
                const vm = this;

                vm.enableDisableDomain(vm.customDomain);
            },

            handleMembershipAlert () {
                const vm = this;

                if (vm.membership.has_white_label == 0) {
                    const options = Helper.swalWarningOptions('', `To access the White Label Feature, you will need to upgrade your current plan to the PRO plan.`);

                    Swal.fire(options);
                }
            }
        }
    }
</script>

<style scoped>
    .custDomain_info {
        padding: 15px 20px;
        background: #fff;
        border-radius: 8px;
    }

    .custDomain_info p {
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 10px;
        color: #5a5a5a;
    }

    .custDomain_info h5 {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        padding: 5px 0;
    }

    .custDomain_info ul {
        padding: 10px 0 20px 0;
    }

    .custDomain_info ul li {
        padding: 4px 0 4px 25px;
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        position: relative;
    }

    .custDomain_info ul li i {
        font-size: 11px;
        position: absolute;
        left: 5px;
        top: 7px;
        color: #2f7eed;
    }

    .dropdown .drp_wrapper {
        display: none;
    }

    .dropdown.active .drp_wrapper {
        display: block;
    }

    .result_wpr table td:first-child,
    .result_wpr table th:first-child {
        min-width: 32px;
    }

    .action_wpr a.btn {
        text-decoration: none;
        font-size: 11px;
        line-height: 34px;
        background: #fff;
    }
    .add_btn i{
        font-size: 11px !important;
    }

    .break-all {
        word-break: break-all;
    }
    .result_wpr.small table th:first-child{
        padding: 8px 12px;
        text-align: right;
    }
    .result_wpr.small table td:first-child{
        padding: 15px 12px;
        text-align: right;
        font-size: 11px;
    }
    .result_wpr.small table td{
        font-size: 13px;
        line-height: 18px;
    }

    .result_wpr.new.small .actions > ul li.search_area.active input[type=text] {
        height: 20px;
    }
    h4.label{
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #0e101a;
        padding-bottom: 7px;
    }
    .domain_name{
        font-size: 17px;
        line-height: 25px;
        font-weight: 400;
        color: #5a5a5a;
        padding-bottom: 25px;
    }
    .domain_info{
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
    }
    @media(max-width: 767px){
        /* .result_wpr.small .actions > ul{
            flex-wrap: inherit !important;
        }
        .result_wpr.small .actions > ul > li{
            order: unset !important;
            border-radius: inherit !important;
            border-bottom: inherit !important;
        }
        .result_wpr.small .actions > ul li, .result_wpr.small .actions > ul li input{
            font-size: 11px !important;
        }
        .result_wpr.small .actions > ul > li.list_info{
            flex: 0 1 50px !important;
        }
        .result_wpr.small .actions > ul > li.optionDrops{
            flex: 0 1 200px !important;
        } */
        .result_wpr.small table{
            min-width: 500px;
        }
        .result_wpr.small .actions{
            min-width: 100% !important;
        }
        .result_wpr.new.small .actions > ul li.search_area{
            width: 100%;
            order: -1;
        }
        .result_wpr.new.small .actions > ul li.search_area input[type=text]{
            padding: 0 15px 0 0;
        }
        .result_wpr.new.small .actions > ul li.contacts-tabs{
            margin-left: auto;
        }
        .result_wpr.new.small .actions > ul li.contacts-tabs .dropdown_wpr{
            left: auto;
            right: 1px;
        }
    }
</style>
